.bg-complete-progress-bar {
  background: linear-gradient(
    270.05deg,
    #6add7c 0.03%,
    #31d32e 47.91%,
    #68dd00 99.94%
  );
}

.bg-complete-progress-bar-upper {
  background: linear-gradient(
    269.98deg,
    #a0fe97 0.02%,
    #47ff29 52.99%,
    #12d301 99.99%
  );
}

.bg-complete-progress-bar-upper2 {
  background: linear-gradient(
    269.98deg,
    #ccffc7 0.03%,
    #5dff5a 52.09%,
    #87fd3f 99.99%
  );
}